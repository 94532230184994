<template>
  <div ref="draggableContainer" id="draggable-container" :showOrderWindow="'70'"  :style="{ top: position.top + 'px', left: position.left + 'px' }">
    <div id="draggable-header" @mousedown="startDrag" >
      <slot name="header" id="header"></slot>
    </div>
    <slot name="main" id="main"></slot>
    <slot name="footer" id="footer"></slot>
  </div>
</template>

<script>
export default {
  name: "DraggableDiv",
  data: function() {
    return {
      isDragging: false,
      position: {
      top: 0,
      left: 70,
    },
    offset:{
      x: 0,
      y: 0,
    },
    boundary:{
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    }
    };
  },
  methods: {
    setBoundary() {
      
      const windowWidth = window.innerWidth;
      const windowHeight = window.innerHeight;
      this.boundary = {
        top: 0,
        left: 0,
        right: windowWidth,
        bottom: windowHeight,
      };
      if(this.position && this.position.top == 0){
        this.position.top =  windowHeight- 360
      }
    },
    startDrag(event) {
      this.isDragging = true;
      this.offset.x = event.clientX - this.position.left;
      this.offset.y = event.clientY - this.position.top;

      document.addEventListener("mousemove", this.onMouseMove);
      document.addEventListener("mouseup", this.stopDrag);
    },
    onMouseMove(event) {
      if (this.isDragging) {
        const newLeft = event.clientX - this.offset.x;
        const newTop = event.clientY - this.offset.y;

        // Ensure the draggable element stays within the window boundaries
        this.position.left = Math.max(
          this.boundary.left,
          Math.min(newLeft, this.boundary.right - this.$el.offsetWidth)
        );
        this.position.top = Math.max(
          this.boundary.top,
          Math.min(newTop, this.boundary.bottom - this.$el.offsetHeight)
        );
      }
    },
    stopDrag() {
      this.isDragging = false;
      document.removeEventListener("mousemove", this.onMouseMove);
      document.removeEventListener("mouseup", this.stopDrag);
    },
  },
  mounted() {
    this.setBoundary();
    window.addEventListener("resize", this.setBoundary);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setBoundary);
  },
};
</script>

<style>
/* draggable div css */
#draggable-container {
  /* left: 64px; */
  /* top:40%; */
  position: absolute;
  z-index: 99;
  height: auto !important;
   width:550px;
  filter: drop-shadow(3px 3px 8px rgba(0.25, 0.25, 0.5, 0.45)) !important;
  /* transition: all 0.3s; */
}
#draggable-header {
  z-index: 10;
}
[showOrderWindow = '470']{
  left: 470px;
}
[showOrderWindow = '520']{
  left: 520px;
}
[showOrderWindow = '70']{
  left: 70px;
}



/* draggable div css end */
</style>